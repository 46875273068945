import Button from "@components/Button";
import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import RadioInputButton from "@components/RadioInputButton";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

type Topic =
  | "collaboration"
  | "goal-planning"
  | "idea-generation"
  | "learning"
  | "problem-solving"
  | "productivity"
  | "project-management"
  | "strategy";

export default function TopicsPage() {
  const [topic, setTopic] = useState<Topic | null>(null);

  const { t } = useTranslation();

  const checkboxInputButtonClassName =
    "growth-button-input !rounded-xl content-center";

  const heading = () => {
    switch (topic) {
      case "collaboration":
        return t(
          "pages.onboarding.growth.topics.breakDownTasksIntoManageableStepsForEfficientTeamwork",
        );
      case "goal-planning":
        return t(
          "pages.onboarding.growth.topics.makeProjectsEasierByVisualizingEverythingInOnePlace",
        );
      case "idea-generation":
        return t(
          "pages.onboarding.growth.topics.saveTimeByOrganizingYourIdeasEfficiently",
        );
      case "learning":
        return t("pages.onboarding.growth.topics.learn10xFaster");
      case "problem-solving":
        return t(
          "pages.onboarding.growth.topics.visualizeSolutionsToSimplifyAndTackleChallengesEffectively",
        );
      case "productivity":
        return t(
          "pages.onboarding.growth.topics.boostYourProductivityByVisualizingWorkflows",
        );
      case "project-management":
        return t(
          "pages.onboarding.growth.topics.streamlineComplexTasksIntoManageableStepsForEfficiency",
        );
      case "strategy":
        return t(
          "pages.onboarding.growth.topics.experienceClarityInYourBusinessStrategy",
        );
    }
  };

  return (
    <Layout>
      {topic ? (
        <div className="flex h-full flex-col text-center">
          <div className="shrink-0">
            <ProgressBar />
          </div>

          <div className="flex grow flex-col items-center justify-center">
            <span className="text-7xl">🚀</span>

            <h1 className="custom-heading my-5">{heading()}</h1>

            <p className="text-lg">
              <Trans
                components={{
                  colored: <span className="custom-text-colored font-bold" />,
                }}
                i18nKey="pages.onboarding.learnFaster.text"
              />
            </p>
          </div>

          <Form className="shrink-0" method="post">
            <input type="hidden" name="topic" value={topic} />

            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      ) : (
        <>
          <div className="sticky top-0 shrink-0 bg-custom-black-transparent">
            <ProgressBar />

            <h1 className="custom-heading mt-8">
              {t("pages.onboarding.growth.topics.heading")}
            </h1>

            <p className="custom-subheading">
              {t("pages.onboarding.growth.topics.subheading")}
            </p>
          </div>

          <div className="flex grow flex-col justify-center py-8">
            <div className="grid grid-cols-2 gap-5">
              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.strategy")}
                name="topic"
                onChange={() => setTopic("strategy")}
                text={t(
                  "pages.onboarding.growth.topics.visualizeBusinessGoalsAndPlans",
                )}
                value="strategy"
              />

              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.collaboration")}
                name="topic"
                onChange={() => setTopic("collaboration")}
                text={t(
                  "pages.onboarding.growth.topics.facilitateTeamBrainstorming",
                )}
                value="collaboration"
              />

              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.learning")}
                name="topic"
                onChange={() => setTopic("learning")}
                text={t(
                  "pages.onboarding.growth.topics.understandAndRetainNewInformation",
                )}
                value="learning"
              />

              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.problemSolving")}
                name="topic"
                onChange={() => setTopic("problem-solving")}
                text={t(
                  "pages.onboarding.growth.topics.exploreCreativeSolutions",
                )}
                value="problem-solving"
              />

              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.projectManagement")}
                name="topic"
                onChange={() => setTopic("project-management")}
                text={t("pages.onboarding.growth.topics.breakDownComplexTasks")}
                value="project-management"
              />

              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.productivity")}
                name="topic"
                onChange={() => setTopic("productivity")}
                text={t(
                  "pages.onboarding.growth.topics.organizeMyThoughtsAndTasks",
                )}
                value="productivity"
              />

              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.goalPlanning")}
                name="topic"
                onChange={() => setTopic("goal-planning")}
                text={t(
                  "pages.onboarding.growth.topics.mapOutPersonalOrProfessionalGoals",
                )}
                value="goal-planning"
              />

              <RadioInputButton
                className={checkboxInputButtonClassName}
                label={t("pages.onboarding.growth.topics.ideaGeneration")}
                name="topic"
                onChange={() => setTopic("idea-generation")}
                text={t(
                  "pages.onboarding.growth.topics.brainstormAndConnectNewIdeas",
                )}
                value="idea-generation"
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
