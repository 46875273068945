import { GlobalContext, GlobalContextProps } from "@context/GlobalContext";
import { useContext } from "react";

export default function useGlobalContext(): GlobalContextProps {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }

  return context;
}
