import Dialog from "@components/Dialog";
import Spinner from "@components/Spinner";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function ResetPasswordDialog({ onClose, open }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      navigate("/account/sign-in");
    }, 3000);
  }, [navigate]);

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="space-y-10">
        <p className="custom-heading">
          {t("pages.account.resetPassword.dialog.title")}
        </p>

        <div className="flex justify-center">
          <Spinner />
        </div>

        <p className="text-center">
          <Trans
            components={{
              signInLink: (
                <Link className="underline" to={"/account/sign-in"} />
              ),
            }}
            i18nKey="pages.account.resetPassword.dialog.text"
          />
        </p>
      </div>
    </Dialog>
  );
}
