import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function SuccessPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0 space-y-8">
          <ProgressBar />

          <h1 className="custom-heading">
            {t("pages.onboarding.growth.success.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="growth-button-input"
            label={t("pages.onboarding.growth.success.achievingMilestones")}
            name="success"
            onChange={handleChange}
            value="achieving-milestones"
          />

          <RadioInputButton
            className="growth-button-input"
            label={t("pages.onboarding.growth.success.gainingKnowledge")}
            name="success"
            onChange={handleChange}
            value="gaining-knowledge"
          />

          <RadioInputButton
            className="growth-button-input"
            label={t("pages.onboarding.growth.success.completingProjects")}
            name="success"
            onChange={handleChange}
            value="completing-projects"
          />

          <RadioInputButton
            className="growth-button-input"
            label={t("pages.onboarding.growth.success.receivingFeedback")}
            name="success"
            onChange={handleChange}
            value="receiving-feedback"
          />
        </Form>
      </div>
    </Layout>
  );
}
