import i18n from "../i18n";

export default function localizeCurrency({
  amountInCents,
  currency,
}: {
  amountInCents: number | undefined;
  currency: string | undefined;
}): string | undefined {
  if (!amountInCents || !currency) return;

  const amount = Math.floor(amountInCents) / 100;

  return new Intl.NumberFormat(i18n.language, {
    currency: currency,
    style: "currency",
  }).format(amount);
}
