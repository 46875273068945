import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import CompanyPage from "@pages/onboarding/CompanyPage";
import AdvanceSkillsPage from "@pages/onboarding/growth/AdvanceSkillsPage";
import AgePage from "@pages/onboarding/growth/AgePage";
import FullPotentialPage from "@pages/onboarding/growth/FullPotentialPage";
import GenderPage from "@pages/onboarding/growth/GenderPage";
import LearnPage from "@pages/onboarding/growth/LearnPage";
import ProductivityPage from "@pages/onboarding/growth/ProductivityPage";
import SuccessFollowUpPage from "@pages/onboarding/growth/SuccessFollowUpPage";
import SuccessPage from "@pages/onboarding/growth/SuccessPage";
import TailoredPage from "@pages/onboarding/growth/TailoredPage";
import TopicsPage from "@pages/onboarding/growth/TopicsPage";
import WhatYouGetPage from "@pages/onboarding/growth/WhatYouGetPage";
import { Navigate, Outlet } from "react-router-dom";

const growthOnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/growth",
    children: [
      {
        index: true,
        element: <Navigate to="age" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AdvanceSkillsPage />,
        path: "advance-skills",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AgePage />,
        path: "age",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FullPotentialPage />,
        path: "full-potential",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GenderPage />,
        path: "gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnPage />,
        path: "learn",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProductivityPage />,
        path: "productivity",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <SuccessPage />,
        path: "success",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <SuccessFollowUpPage />,
        path: "success-follow-up",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TailoredPage />,
        path: "tailored",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TopicsPage />,
        path: "topics",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <WhatYouGetPage />,
        path: "what-you-get",
      },
    ],
  },
];

export default growthOnboardingRoutes;
