import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function ProductivityPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="custom-heading">
            {t("pages.onboarding.productivity.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="border-custom-beige bg-custom-beige-transparent hover:bg-custom-beige has-[:checked]:bg-custom-beige"
            label={t("pages.onboarding.productivity.stayingFocused")}
            name="productivity"
            onChange={handleChange}
            value="staying-focused"
          />

          <RadioInputButton
            className="border-custom-red bg-custom-red-transparent hover:bg-custom-red has-[:checked]:bg-custom-red"
            label={t("pages.onboarding.productivity.timeManagement")}
            name="productivity"
            onChange={handleChange}
            value="time-management"
          />

          <RadioInputButton
            className="border-custom-blue bg-custom-blue-transparent hover:bg-custom-blue has-[:checked]:bg-custom-blue"
            label={t("pages.onboarding.productivity.maintainingMotivation")}
            name="productivity"
            onChange={handleChange}
            value="maintaining-motivation"
          />

          <RadioInputButton
            className="border-custom-green bg-custom-green-transparent hover:bg-custom-green has-[:checked]:bg-custom-green"
            label={t("pages.onboarding.productivity.taskOverload")}
            name="productivity"
            onChange={handleChange}
            value="task-overload"
          />

          <RadioInputButton
            className="border-custom-yellow bg-custom-yellow-transparent hover:bg-custom-yellow has-[:checked]:bg-custom-yellow"
            label={t("pages.onboarding.productivity.findingEffectiveTools")}
            name="productivity"
            onChange={handleChange}
            value="finding-effective-tools"
          />
        </Form>
      </div>
    </Layout>
  );
}
