import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { CancelationType } from "@pages/helpCenter/CancelationPage";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { CancelRevenueCatSubscriptionsData } from "src/types";
import LayoutWhite from "../../components/LayoutWhite";

type CancelationCompletedPageLocationState =
  CancelRevenueCatSubscriptionsData & {
    cancelationType: CancelationType;
  };

export default function CancelationCompletedPage() {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    activeAppStoreSubscriptionsCount,
    activePlayStoreSubscriptionsCount,
    canceledStripeSubscriptionsCount,
    cancelationType,
  } = (location.state as CancelationCompletedPageLocationState) || {};

  const appleUrl = "https://support.apple.com/118223";

  const googleUrl = "https://support.google.com/googleplay/workflow/9813244";

  const hasAppleSubscription = activeAppStoreSubscriptionsCount > 0;

  const hasGoogleSubscription = activePlayStoreSubscriptionsCount > 0;

  const hasAppleAndGoogleSubscriptions =
    hasAppleSubscription && hasGoogleSubscription;

  const hasAppleOrGoogleSubscription =
    hasAppleSubscription || hasGoogleSubscription;

  const hasCanceledWebSubscription = canceledStripeSubscriptionsCount > 0;

  const followGuidelinesTranslation = () => {
    if (hasAppleAndGoogleSubscriptions)
      return t(
        "pages.helpCenter.cancelationCompleted.followGoogleAndAppleGuidelines",
      );

    if (hasAppleSubscription)
      return t("pages.helpCenter.cancelationCompleted.followAppleGuidelines");

    return t("pages.helpCenter.cancelationCompleted.followGoogleGuidelines");
  };

  const regardingSubscriptionTranslation = () => {
    if (hasAppleAndGoogleSubscriptions)
      return t("pages.helpCenter.regardingGoogleAndAppleSubscriptions");

    if (hasGoogleSubscription)
      return t("pages.helpCenter.regardingGoogleSubscription");

    return t("pages.helpCenter.regardingAppleSubscription");
  };

  if (!hasAppleOrGoogleSubscription || cancelationType === "web") {
    return (
      <LayoutWhite>
        <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
          <CheckCircleIcon className="h-20 text-help-center-success" />

          <p className="text-2xl font-bold">{t("pages.helpCenter.thankYou")}</p>

          <p>
            <Trans
              components={{
                bold: <span className="font-bold text-help-center-success" />,
              }}
              i18nKey="pages.helpCenter.cancelationCompleted.subscriptionSuccessfullyCanceled"
            />
          </p>
        </div>
      </LayoutWhite>
    );
  }

  return (
    <LayoutWhite>
      <div className="flex flex-col items-center justify-center space-y-8 text-center">
        <p className="text-2xl font-bold">
          {t("pages.helpCenter.shared.furtherActionNeeded")}
        </p>

        {hasCanceledWebSubscription && (
          <p>
            <Trans
              components={{
                bold: <span className="font-bold text-help-center-success" />,
              }}
              i18nKey="pages.helpCenter.cancelationCompleted.webSubscriptionCanceled"
            />
          </p>
        )}

        {hasAppleOrGoogleSubscription && cancelationType === "all" && (
          <>
            <p className="font-bold">{regardingSubscriptionTranslation()}</p>

            <p>
              {followGuidelinesTranslation()}{" "}
              <span className="font-bold">
                {t(
                  "pages.helpCenter.cancelationCompleted.followInstructionsToCancel",
                )}
              </span>
            </p>
          </>
        )}

        {hasGoogleSubscription && cancelationType === "all" && (
          <div className="space-y-2">
            <p className="font-bold">Google:</p>

            <div className="w-full rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center sm:w-auto">
              <Link
                className="break-all underline"
                target="_blank"
                to={googleUrl}
              >
                {googleUrl}
              </Link>
            </div>
          </div>
        )}

        {hasAppleSubscription && cancelationType === "all" && (
          <div className="space-y-2">
            <p className="font-bold">Apple:</p>

            <div className="w-full rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center sm:w-auto">
              <Link
                className="break-all underline"
                target="_blank"
                to={appleUrl}
              >
                {appleUrl}
              </Link>
            </div>
          </div>
        )}
      </div>
    </LayoutWhite>
  );
}
