import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function LearnPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="custom-heading">
            {t("pages.onboarding.learn.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="border-custom-yellow bg-custom-yellow-transparent hover:bg-custom-yellow has-[:checked]:bg-custom-yellow"
            emoji="👀"
            label={t("pages.onboarding.learn.visual")}
            name="learn"
            onChange={handleChange}
            value="visual"
          />

          <RadioInputButton
            className="border-custom-red bg-custom-red-transparent hover:bg-custom-red has-[:checked]:bg-custom-red"
            emoji="🎧"
            label={t("pages.onboarding.learn.audio")}
            name="learn"
            onChange={handleChange}
            value="audio"
          />

          <RadioInputButton
            className="border-custom-green bg-custom-green-transparent hover:bg-custom-green has-[:checked]:bg-custom-green"
            emoji="📖"
            label={t("pages.onboarding.learn.reading")}
            name="learn"
            onChange={handleChange}
            value="reading"
          />
        </Form>
      </div>
    </Layout>
  );
}
