import { createContext } from "react";

export interface GlobalContextProps {
  guestId: string | undefined;
  progress: number;
  setGuestId: (guestId: string) => void;
  setProgress: (step: number) => void;
}

export const GlobalContext = createContext<GlobalContextProps | undefined>(
  undefined,
);
