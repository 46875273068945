import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import AgePage from "@pages/onboarding/AgePage";
import CompanyPage from "@pages/onboarding/CompanyPage";
import GenderPage from "@pages/onboarding/GenderPage";
import GrowPage from "@pages/onboarding/GrowPage";
import LearnFasterPage from "@pages/onboarding/LearnFasterPage";
import LearningPage from "@pages/onboarding/LearningPage";
import LearnPage from "@pages/onboarding/LearnPage";
import ProductivityPage from "@pages/onboarding/ProductivityPage";
import ProgressPage from "@pages/onboarding/ProgressPage";
import TailoredPage from "@pages/onboarding/TailoredPage";
import TopicsPage from "@pages/onboarding/TopicsPage";
import { Navigate, Outlet } from "react-router-dom";

const v1OnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/v1",
    children: [
      {
        index: true,
        element: <Navigate to="age" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AgePage />,
        path: "age",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GenderPage />,
        path: "gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GrowPage />,
        path: "grow",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnPage />,
        path: "learn",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnFasterPage />,
        path: "learn-faster",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearningPage />,
        path: "learning",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProductivityPage />,
        path: "productivity",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProgressPage />,
        path: "progress",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TailoredPage />,
        path: "tailored",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TopicsPage />,
        path: "topics",
      },
    ],
  },
];

export default v1OnboardingRoutes;
