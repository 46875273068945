import imageUrl from "@assets/images.png";
import ScreenshotPage from "@pages/onboarding/v2/ScreenshotPage";
import { useTranslation } from "react-i18next";

export default function ImagesPage() {
  const { t } = useTranslation();

  return (
    <ScreenshotPage
      heading={t("pages.onboarding.v2.images.heading")}
      imageUrl={imageUrl}
      subheading={t("pages.onboarding.v2.images.subheading")}
    />
  );
}
