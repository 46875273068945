import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function GrowPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="custom-heading">
            {t("pages.onboarding.grow.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="border-custom-green bg-custom-green-transparent hover:bg-custom-green has-[:checked]:bg-custom-green"
            label={t("pages.onboarding.grow.beMoreProductive")}
            name="gender"
            onChange={handleChange}
            value="be-more-productive"
          />

          <RadioInputButton
            className="border-custom-purple bg-custom-purple-transparent hover:bg-custom-purple has-[:checked]:bg-custom-purple"
            label={t("pages.onboarding.grow.improveMyWellbeing")}
            name="gender"
            onChange={handleChange}
            value="improve-my-wellbeing"
          />

          <RadioInputButton
            className="border-custom-blue bg-custom-blue-transparent hover:bg-custom-blue has-[:checked]:bg-custom-blue"
            label={t("pages.onboarding.grow.growInMyCareer")}
            name="gender"
            onChange={handleChange}
            value="grow-in-my-career"
          />

          <RadioInputButton
            className="border-custom-red bg-custom-red-transparent hover:bg-custom-red has-[:checked]:bg-custom-red"
            label={t("pages.onboarding.grow.improveMyRelationships")}
            name="gender"
            onChange={handleChange}
            value="improve-my-relationships"
          />

          <RadioInputButton
            className="border-custom-beige bg-custom-beige-transparent hover:bg-custom-beige has-[:checked]:bg-custom-beige"
            label={t("pages.onboarding.grow.boostMyFinances")}
            name="gender"
            onChange={handleChange}
            value="boost-my-finances"
          />

          <RadioInputButton
            className="border-custom-yellow bg-custom-yellow-transparent hover:bg-custom-yellow has-[:checked]:bg-custom-yellow"
            label={t("pages.onboarding.grow.imJustBrowsing")}
            name="gender"
            onChange={handleChange}
            value="im-just-browsing"
          />
        </Form>
      </div>
    </Layout>
  );
}
