import useGlobalContext from "@hooks/useGlobalContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ALL_STEPS = [
  "age",
  "tailored",
  "gender",
  "topics",
  "learn",
  "productivity",
  "success",
  "success-follow-up",
  "advance-skills",
  "full-potential",
];

const HIGHLIGHTED_STEPS = [
  "age",
  "learn",
  "success-follow-up",
  "full-potential",
];

export default function ProgressBar() {
  const location = useLocation();
  const { progress, setProgress } = useGlobalContext();

  const currentRoute = location.pathname.split("/").pop();
  const steps = ALL_STEPS;
  const currentStep = steps.indexOf(currentRoute ?? "");

  const width = `${progress * 100}%`;

  const getStepClassName = ({
    index,
    step,
  }: {
    index: number;
    step: string;
  }): string => {
    const hideStep = !HIGHLIGHTED_STEPS.includes(step);
    const pastStep = steps.indexOf(step) <= currentStep;
    const lastStep = index === steps.length - 1;

    if (hideStep) return "border border-transparent bg-transparent";
    if (pastStep) return "border border-step-active bg-step-active";
    if (lastStep) return "border border-step-inactive bg-custom-black";

    return "border border-step-active bg-custom-black";
  };

  useEffect(() => {
    const setNewProgress = () => {
      if (!currentRoute) return;

      setProgress(currentStep / (steps.length - 1));
    };

    setTimeout(() => setNewProgress(), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div aria-hidden="true" className="relative">
      <div className="bg-step-inactive overflow-hidden rounded-full">
        <div
          className="bg-step-active h-px rounded-full transition-all duration-1000 ease-out"
          style={{ width: width }}
        />

        <div className="absolute -mt-1.5 flex w-full justify-between">
          {steps.map((step, index) => {
            const stepClassName = getStepClassName({ index, step });

            return (
              <span
                className={`${stepClassName} block h-3 w-3 rounded-full`}
                key={step}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
