import { getUserId } from "@helpers/sessionStorage";
import { insertWebFunnelLog } from "@helpers/supabase";
import useGlobalContext from "@hooks/useGlobalContext";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export default function useLogWebFunnel() {
  const location = useLocation();
  const { guestId: guestIdFromGlobalContext, setGuestId } = useGlobalContext();

  // NOTE: Set up on first render.

  useEffect(() => {
    const guestId = uuidv4();

    setGuestId(guestId);

    if (!localStorage.getItem("guestId"))
      localStorage.setItem("guestId", guestId);

    if (!sessionStorage.getItem("guestId"))
      sessionStorage.setItem("guestId", guestId);

    if (!Cookies.get("guestId")) Cookies.set("guestId", guestId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: Log on every location change.

  useEffect(() => {
    const awaitInserWebFunneltLog = async () => {
      await insertWebFunnelLog({
        fbcFromCookie: Cookies.get("_fbc"),
        fbcFromLocalStorage: localStorage.getItem("fbc"),
        fbpFromCookie: Cookies.get("_fbp"),
        guestIdFromCookie: Cookies.get("guestId"),
        guestIdFromGlobalContext,
        guestIdFromLocalStorage: localStorage.getItem("guestId"),
        guestIdFromSessionStorage: sessionStorage.getItem("guestId"),
        locationPathname: location.pathname,
        locationSearch: location.search,
        loggedAt: new Date().toISOString(),
        userAgent: navigator.userAgent,
        userId: await getUserId(),
      });
    };

    void awaitInserWebFunneltLog();
  }, [guestIdFromGlobalContext, location]);
}
