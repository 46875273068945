import chatImageUrl from "@assets/chats.png";
import ScreenshotPage from "@pages/onboarding/v2/ScreenshotPage";
import { useTranslation } from "react-i18next";

export default function ChatsPage() {
  const { t } = useTranslation();

  return (
    <ScreenshotPage
      heading={t("pages.onboarding.v2.chats.heading")}
      imageUrl={chatImageUrl}
      subheading={t("pages.onboarding.v2.chats.subheading")}
    />
  );
}
