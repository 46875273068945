import mindMapImageUrl from "@assets/mind_maps.png";
import ScreenshotPage from "@pages/onboarding/v2/ScreenshotPage";
import { useTranslation } from "react-i18next";

export default function MindMapsPage() {
  const { t } = useTranslation();

  return (
    <ScreenshotPage
      heading={t("pages.onboarding.v2.mindMaps.heading")}
      imageUrl={mindMapImageUrl}
      subheading={t("pages.onboarding.v2.mindMaps.subheading")}
    />
  );
}
