import BulletPoint from "@components/BulletPoint";
import { useTranslation } from "react-i18next";

export default function Features() {
  const { t } = useTranslation();

  return (
    <div className="space-y-11">
      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints.learnFasterText")}
        title={t("pages.checkout.plans.bulletPoints.learnFasterTitle")}
      />

      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints.aiHelperText")}
        title={t("pages.checkout.plans.bulletPoints.aiHelperTitle")}
      />

      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints.youTubeVideoText")}
        title={t("pages.checkout.plans.bulletPoints.youTubeVideoTitle")}
      />

      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints.chatWithMindMapText")}
        title={t("pages.checkout.plans.bulletPoints.chatWithMindMapTitle")}
      />
    </div>
  );
}
