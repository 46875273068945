import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import RadioInputButton from "@components/RadioInputButton";
import VisualMindLogoSvg from "@components/VisualMindLogoSvg";
import { useTranslation } from "react-i18next";
import { Form, Link, useSubmit } from "react-router-dom";

export default function AgePage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  const radioInputButtonClassName = "growth-button-input !rounded-xl !p-8";

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="flex shrink-0 items-center justify-between">
          <VisualMindLogoSvg />

          <Link className="text-lg hover:opacity-80" to="/account/sign-in">
            {t("shared.signIn")}
          </Link>
        </div>

        <div className="mt-11 shrink-0 space-y-8">
          <ProgressBar />

          <h1 className="custom-heading mb-4">
            {t("pages.onboarding.growth.age.heading")}
          </h1>
        </div>

        <div className="flex grow items-center justify-center">
          <Form method="post">
            <div className="grid grid-cols-2 gap-4">
              <RadioInputButton
                className={radioInputButtonClassName}
                label={t("pages.onboarding.age.age18to24")}
                name="age"
                onChange={handleChange}
                value="18-24"
              />

              <RadioInputButton
                className={radioInputButtonClassName}
                label={t("pages.onboarding.age.age25to34")}
                name="age"
                onChange={handleChange}
                value="25-34"
              />

              <RadioInputButton
                className={radioInputButtonClassName}
                label={t("pages.onboarding.age.age35to44")}
                name="age"
                onChange={handleChange}
                value="35-44"
              />

              <RadioInputButton
                className={radioInputButtonClassName}
                label={t("pages.onboarding.age.age45+")}
                name="age"
                onChange={handleChange}
                value="45+"
              />
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
}
