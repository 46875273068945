import Button from "@components/Button";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function ScreenshotPage({
  heading,
  imageUrl,
  subheading,
}: {
  heading: string;
  imageUrl: string;
  subheading: string;
}) {
  const { t } = useTranslation();

  return (
    <main className="custom-container flex h-dvh flex-col gap-16 overflow-y-auto pt-8">
      <div className="shrink-0 space-y-6">
        <h1 className="custom-heading">{heading}</h1>

        <h2 className="v2-subheading">{subheading}</h2>
      </div>

      {/* NOTE: Mobile (hidden on desktop) */}

      <div
        className="mx-4 flex grow flex-col justify-end pb-14 md:hidden"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Form className="-mx-4" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>

      {/* NOTE: Desktop (hidden on mobile) */}

      <div className="hidden grow items-center justify-center overflow-hidden md:flex">
        <img alt={subheading} className="max-h-full w-auto" src={imageUrl} />
      </div>

      <div className="hidden shrink-0 pb-14 md:block">
        <Form method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </main>
  );
}
