import ButtonSpinner from "@components/ButtonSpinner";

interface ButtonProps {
  disabled?: boolean;
  onClick: () => void;
  submitting?: boolean;
  title: string;
  type: "primary" | "primary-large" | "secondary";
}

export default function Button({
  disabled = false,
  onClick,
  submitting = false,
  title,
  type,
}: ButtonProps) {
  const getButtonSpinnerClassName = () => {
    return type === "secondary" ? "!text-black" : "!text-white";
  };

  const getClassName = () => {
    switch (type) {
      case "primary":
        return "help-center-button-primary";
      case "primary-large":
        return "help-center-button-primary-large";
      case "secondary":
        return "help-center-button-secondary";
    }
  };

  return (
    <button
      className={getClassName()}
      disabled={disabled || submitting}
      onClick={onClick}
      title={title}
    >
      {submitting && <ButtonSpinner className={getButtonSpinnerClassName()} />}

      {title}
    </button>
  );
}
