export default function RadioInputButton({
  className,
  emoji,
  label,
  name,
  onChange,
  text,
  value,
}: {
  className?: string;
  emoji?: string;
  label?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string;
  value: string;
}) {
  const emojiSize = label ? "text-normal" : "text-3xl";

  return (
    <label className={`${className} custom-button-input`}>
      <input
        className="hidden"
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />

      {emoji && <span className={`${emojiSize} mr-1`}>{emoji}</span>}

      {label}

      {text && (
        <>
          <br />
          <span className="text-xs font-normal">{text}</span>
        </>
      )}
    </label>
  );
}
