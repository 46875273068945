import Layout from "@components/Layout";
import SubscriptionDialog from "@components/SubscriptionDialog";
import VisualMindLogoSvg from "@components/VisualMindLogoSvg";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { GetSubscriptionLoaderData } from "src/loaders/getSubscriptionLoader";
import GooglePlayAndAppStoreBadgeLinks from "../../components/GooglePlayAndAppStoreBadgeLinks";

export default function SubscriptionPage() {
  const { revenueCatSubscription, userEmail } =
    useLoaderData() as GetSubscriptionLoaderData;

  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <div className="flex shrink-0 items-center justify-between">
          <VisualMindLogoSvg />

          {revenueCatSubscription && (
            <button
              className="rounded-full bg-white p-4 text-xs font-bold text-black hover:opacity-80"
              onClick={() => setOpenSubscriptionDialog(true)}
              type="button"
            >
              {t("pages.account.subscription.subscriptionInfo")}
            </button>
          )}
        </div>

        <div className="my-8 flex grow flex-col justify-center space-y-8 sm:space-y-16">
          <h1 className="custom-heading">
            <Trans
              components={{ colored: <span className="custom-text-colored" /> }}
              i18nKey="pages.account.subscription.heading"
            />
          </h1>

          <p className="text-center text-xl font-bold underline">
            {t("pages.account.subscription.noNewSubscription")}
          </p>

          <p className="whitespace-pre-wrap text-center">
            <Trans
              components={{
                bold: <span className="break-all font-bold" />,
              }}
              values={{ email: userEmail }}
              i18nKey="pages.account.subscription.signIntoApp"
            />
          </p>

          <GooglePlayAndAppStoreBadgeLinks />
        </div>
      </Layout>

      {openSubscriptionDialog && revenueCatSubscription && (
        <SubscriptionDialog
          onClose={() => setOpenSubscriptionDialog(false)}
          open={openSubscriptionDialog}
          revenueCatSubscription={revenueCatSubscription}
        />
      )}
    </>
  );
}
