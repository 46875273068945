import { getUser } from "@helpers/supabase";
import { AuthUser } from "@supabase/supabase-js";
import { redirect } from "react-router-dom";

export default async function getUserLoader({
  request,
}: {
  request: Request;
}): Promise<AuthUser | Response> {
  const { data } = await getUser();

  if (!data?.user) {
    const url = new URL(request.url);
    const urlSearchParams = new URLSearchParams(url.search);

    if (url.pathname) {
      urlSearchParams.append("path", url.pathname);
    }

    return redirect("/account/sign-in?" + urlSearchParams.toString());
  }

  return data.user;
}
