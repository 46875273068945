import summariesImageUrl from "@assets/summaries.png";
import ScreenshotPage from "@pages/onboarding/v2/ScreenshotPage";
import { useTranslation } from "react-i18next";

export default function SummariesPage() {
  const { t } = useTranslation();

  return (
    <ScreenshotPage
      heading={t("pages.onboarding.v2.summaries.heading")}
      imageUrl={summariesImageUrl}
      subheading={t("pages.onboarding.v2.summaries.subheading")}
    />
  );
}
