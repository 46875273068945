import Button from "@components/helpCenter/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import LayoutWhite from "../../components/LayoutWhite";
import CancelMultipleSubscriptionsDialog from "../../components/helpCenter/CancelMultipleSubscriptionsDialog";
import { cancelRevenueCatSubscriptions } from "../../helpers/supabase";
import { GetSubscriptionsLoaderData } from "../../loaders/getSubscriptionsLoader";

export type CancelationType = "all" | "web";

export default function CancelationPage() {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<CancelationType | null>(
    null,
  );

  const { cancelableSubscriptions, userEmail } =
    useLoaderData() as GetSubscriptionsLoaderData;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const hasMultipleActiveSubscriptions = cancelableSubscriptions.length > 1;

  const hasNoActiveSubscriptions = cancelableSubscriptions.length === 0;

  const cancelSubscriptions = async (cancelationType: CancelationType) => {
    const {
      activeAppStoreSubscriptionsCount,
      activePlayStoreSubscriptionsCount,
      canceledStripeSubscriptionsCount,
    } = await cancelRevenueCatSubscriptions();

    navigate("/help-center/cancelation/completed", {
      replace: true,
      state: {
        activeAppStoreSubscriptionsCount,
        activePlayStoreSubscriptionsCount,
        canceledStripeSubscriptionsCount,
        cancelationType,
      },
    });
  };

  const handleClick = async () => {
    setIsSubmitting("all");

    if (hasMultipleActiveSubscriptions) {
      setDisplayDialog(true);
    } else {
      await cancelSubscriptions("all");
    }

    setIsSubmitting(null);
  };

  const labelClassName = "font-bold";
  const inputClassName =
    "w-full rounded-lg border-[#D9D9D9] bg-[#F0F0F0] focus:border-black";

  return (
    <>
      <LayoutWhite>
        <div className="shrink-0">
          <h1 className="font-bold">
            {t("pages.helpCenter.cancelation.heading")}
          </h1>
        </div>

        <div className="flex grow flex-col justify-center space-y-16">
          <form className="space-y-8">
            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.emailAddress")}
              </label>

              <input
                className={inputClassName}
                defaultValue={userEmail}
                disabled
                type="email"
              />
            </div>

            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.subject")}
              </label>

              <input
                className={inputClassName}
                defaultValue={t("pages.helpCenter.cancelSubscription")}
                disabled
              />
            </div>
          </form>

          {hasNoActiveSubscriptions && (
            <div className="rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center">
              <p>{t("pages.helpCenter.shared.noActiveSubscription")}</p>
            </div>
          )}
        </div>

        <div className="shrink-0">
          <Button
            disabled={hasNoActiveSubscriptions}
            onClick={handleClick}
            submitting={!!isSubmitting}
            title={t("pages.helpCenter.cancelation.requestCancelation")}
            type="primary-large"
          />
        </div>
      </LayoutWhite>

      {displayDialog && (
        <CancelMultipleSubscriptionsDialog
          cancelableSubscriptions={cancelableSubscriptions}
          cancelSubscriptions={cancelSubscriptions}
          isSubmitting={isSubmitting}
          onClose={() => setDisplayDialog(false)}
          open
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </>
  );
}
