import Button from "@components/Button";
import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function TailoredPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="flex h-full flex-col text-center">
        <div className="shrink-0">
          <ProgressBar />
        </div>

        <div className="flex grow flex-col items-center justify-center">
          <span className="text-7xl">👋</span>

          <h1 className="custom-heading my-5">
            {t("pages.onboarding.growth.tailored.heading")}
          </h1>

          <p className="text-lg">
            {t("pages.onboarding.growth.tailored.text")}
          </p>
        </div>

        <Form className="shrink-0" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </Layout>
  );
}
