import i18next from "i18next";
import googlePlayBadgeCs from "../assets/google_play_badges/google_play_badge_cs.png";
import googlePlayBadgeDa from "../assets/google_play_badges/google_play_badge_da.png";
import googlePlayBadgeDe from "../assets/google_play_badges/google_play_badge_de.png";
import googlePlayBadgeEl from "../assets/google_play_badges/google_play_badge_el.png";
import googlePlayBadgeEn from "../assets/google_play_badges/google_play_badge_en.png";
import googlePlayBadgeEs from "../assets/google_play_badges/google_play_badge_es.png";
import googlePlayBadgeFi from "../assets/google_play_badges/google_play_badge_fi.png";
import googlePlayBadgeFr from "../assets/google_play_badges/google_play_badge_fr.png";
import googlePlayBadgeHi from "../assets/google_play_badges/google_play_badge_hi.png";
import googlePlayBadgeHr from "../assets/google_play_badges/google_play_badge_hr.png";
import googlePlayBadgeHu from "../assets/google_play_badges/google_play_badge_hu.png";
import googlePlayBadgeIt from "../assets/google_play_badges/google_play_badge_it.png";
import googlePlayBadgeNl from "../assets/google_play_badges/google_play_badge_nl.png";
import googlePlayBadgeNo from "../assets/google_play_badges/google_play_badge_no.png";
import googlePlayBadgePl from "../assets/google_play_badges/google_play_badge_pl.png";
import googlePlayBadgePt from "../assets/google_play_badges/google_play_badge_pt.png";
import googlePlayBadgeRo from "../assets/google_play_badges/google_play_badge_ro.png";
import googlePlayBadgeRu from "../assets/google_play_badges/google_play_badge_ru.png";
import googlePlayBadgeSv from "../assets/google_play_badges/google_play_badge_sv.png";
import googlePlayBadgeZh from "../assets/google_play_badges/google_play_badge_zh.png";

// NOTE: Download the official badges here: https://partnermarketinghub.withgoogle.com/brands/google-play/visual-identity/badge-guidelines/?folder=65627

const googlePlayBadges: Record<string, string> = {
  cs: googlePlayBadgeCs,
  da: googlePlayBadgeDa,
  de: googlePlayBadgeDe,
  el: googlePlayBadgeEl,
  en: googlePlayBadgeEn,
  es: googlePlayBadgeEs,
  fi: googlePlayBadgeFi,
  fr: googlePlayBadgeFr,
  hi: googlePlayBadgeHi,
  hr: googlePlayBadgeHr,
  hu: googlePlayBadgeHu,
  it: googlePlayBadgeIt,
  nl: googlePlayBadgeNl,
  no: googlePlayBadgeNo,
  pl: googlePlayBadgePl,
  pt: googlePlayBadgePt,
  ro: googlePlayBadgeRo,
  ru: googlePlayBadgeRu,
  sv: googlePlayBadgeSv,
  zh: googlePlayBadgeZh,
};

export default function getGooglePlayBadge(): string {
  const language = i18next.language.split("-")[0];

  return googlePlayBadges[language] || googlePlayBadgeEn;
}
