import Button from "@components/helpCenter/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import RefundMultipleSubscriptionsDialog from "../../components/helpCenter/RefundMultipleSubscriptionsDialog";
import LayoutWhite from "../../components/LayoutWhite";
import { refundRevenueCatSubscriptions } from "../../helpers/supabase";
import { GetSubscriptionsLoaderData } from "../../loaders/getSubscriptionsLoader";

export type RefundType = "all" | "web";

export default function RefundPage() {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<RefundType | null>(null);

  const { refundableSubscriptions, userEmail } =
    useLoaderData() as GetSubscriptionsLoaderData;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const hasMultipleNonRefundedSubscriptions =
    refundableSubscriptions.length > 1;

  const hasNoNonRefundedSubscriptions = refundableSubscriptions.length === 0;

  const refundSubscriptions = async (refundType: RefundType) => {
    const {
      activeAppStoreSubscriptionsCount,
      activePlayStoreSubscriptionsCount,
      canceledStripeSubscriptionsCount,
      refundedStripeSubscriptionsCount,
    } = await refundRevenueCatSubscriptions();

    navigate("/help-center/refund/completed", {
      replace: true,
      state: {
        activeAppStoreSubscriptionsCount,
        activePlayStoreSubscriptionsCount,
        canceledStripeSubscriptionsCount,
        refundedStripeSubscriptionsCount,
        refundType,
      },
    });
  };

  const handleClick = async () => {
    setIsSubmitting("all");

    if (hasMultipleNonRefundedSubscriptions) {
      setDisplayDialog(true);
    } else {
      await refundSubscriptions("all");
    }

    setIsSubmitting(null);
  };

  const labelClassName = "font-bold";
  const inputClassName =
    "w-full rounded-lg border-[#D9D9D9] bg-[#F0F0F0] focus:ring-0 focus:border-black";

  return (
    <>
      <LayoutWhite>
        <div className="shrink-0">
          <h1 className="font-bold">{t("pages.helpCenter.refund.heading")}</h1>
        </div>

        <div className="flex grow flex-col justify-center space-y-16">
          <form className="space-y-8">
            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.emailAddress")}
              </label>

              <input
                className={inputClassName}
                defaultValue={userEmail}
                readOnly
                type="email"
              />
            </div>

            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.subject")}
              </label>

              <input
                className={inputClassName}
                defaultValue={t("pages.helpCenter.requestRefund")}
                readOnly
              />
            </div>
          </form>

          {hasNoNonRefundedSubscriptions && (
            <div className="rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center">
              <p>{t("pages.helpCenter.shared.noActiveSubscription")}</p>
            </div>
          )}
        </div>

        <div className="shrink-0">
          <Button
            disabled={hasNoNonRefundedSubscriptions}
            onClick={handleClick}
            submitting={!!isSubmitting}
            title={t("pages.helpCenter.refund.requestRefund")}
            type="primary-large"
          />
        </div>
      </LayoutWhite>

      {displayDialog && (
        <RefundMultipleSubscriptionsDialog
          isSubmitting={isSubmitting}
          refundableSubscriptions={refundableSubscriptions}
          onClose={() => setDisplayDialog(false)}
          open
          refundSubscriptions={refundSubscriptions}
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </>
  );
}
