import { getNetAmount } from "@helpers/backend";
import createRevenueCatPurchase from "@helpers/createRevenueCatPurchase";
import { trackPurchaseEvent } from "@helpers/facebookTracking";
import { getUserCountryCode } from "@helpers/sessionStorage";
import { getStripePaymentMethodType, getUser } from "@helpers/supabase";
import { loadStripe, PaymentIntent } from "@stripe/stripe-js";
import { defer } from "react-router-dom";

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
);

export default function completePaymentLoader({
  request,
}: {
  request: Request;
}) {
  const completePaymentPromise = completePayment(request);

  return defer({
    completed: completePaymentPromise,
  });
}

async function completePayment(request: Request) {
  const url = new URL(request.url);
  const clientSecret = url.searchParams.get("payment_intent_client_secret");
  const subscriptionId = url.searchParams.get("subscription_id");

  if (!clientSecret) return false;

  const paymentIntent = await retrieveStripePaymentIntent(clientSecret);

  const paymentMethodId = paymentIntent?.payment_method as string;

  if (!paymentIntent || paymentIntent.status !== "succeeded") {
    return false;
  }

  const userId = await getSupabaseUserId();

  if (!subscriptionId || !userId) return false;

  await createRevenueCatPurchase({
    subscriptionId: subscriptionId,
    userId: userId,
  });

  const countryCode = await getUserCountryCode();

  const { paymentMethodType } =
    await getStripePaymentMethodType(paymentMethodId);

  const netAmount = await getNetAmount({
    countryCode,
    currency: paymentIntent.currency,
    grossAmount: paymentIntent.amount,
    paymentMethodType,
  });

  await trackPurchaseEvent({
    amountInCents: netAmount ?? paymentIntent.amount,
    currency: paymentIntent.currency,
  });

  return true;
}

async function getSupabaseUserId(): Promise<string | undefined> {
  const { data } = await getUser();

  const userId = data?.user?.id;

  return userId;
}

async function retrieveStripePaymentIntent(
  clientSecret: string,
): Promise<PaymentIntent | undefined> {
  const stripe = await stripePromise;

  const response = await stripe?.retrievePaymentIntent(clientSecret);

  return response?.paymentIntent;
}
