import getSubscriptionStatus from "@helpers/getSubscriptionStatus";
import { signInWithPassword } from "@helpers/supabase";
import { redirect } from "react-router-dom";

export interface SignInActionData {
  error: SignInActionError | null;
}

export interface SignInActionError {
  code: string | undefined;
  message: string;
}

export default async function signInAction({
  request,
}: {
  request: Request;
}): Promise<SignInActionData | Response> {
  const formData = await request.formData();
  const email = formData.get("email") as string;
  const password = formData.get("password") as string;

  const { data, error } = await signInWithPassword({
    email,
    password,
  });

  if (error) {
    return { error: { code: error.code, message: error.message } };
  }

  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);
  const path = searchParams.get("path");

  if (path) {
    return redirect(path);
  }

  const userId = data?.user?.id;

  const subscriptionStatus = await getSubscriptionStatus(userId);

  switch (subscriptionStatus) {
    case "active": {
      return redirect("/account/subscription");
    }
    case "inactive": {
      return redirect("/checkout/plans");
    }
    case "none": {
      const url = new URL(request.url);
      const path = url.searchParams.get("path") ?? "/";

      return redirect(path);
    }
    default: {
      throw new Error(
        `Unhandled subscription status: ${subscriptionStatus as string}`,
      );
    }
  }
}
