import { GlobalContext } from "@context/GlobalContext";
import { FC, ReactNode, useState } from "react";

export const GlobalContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [guestId, setGuestId] = useState<string | undefined>();
  const [progress, setProgress] = useState(0);

  return (
    <GlobalContext.Provider
      value={{
        guestId,
        progress,
        setGuestId,
        setProgress,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
