import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import getOnboardingFlagLoader from "@loaders/getOnboardingFlagLoader";
import skipOnboardingLoader from "@loaders/skipOnboardingLoader";
import AgePage from "@pages/onboarding/AgePage";
import CompanyPage from "@pages/onboarding/CompanyPage";
import ComplexConceptsPage from "@pages/onboarding/ComplexConceptsPage";
import FullPotentialPage from "@pages/onboarding/FullPotentialPage";
import GenderPage from "@pages/onboarding/GenderPage";
import growthOnboardingRoutes from "@pages/onboarding/growth/growthOnboardingRoutes";
import LearnFasterPage from "@pages/onboarding/LearnFasterPage";
import LearningOpportunitiesPage from "@pages/onboarding/LearningOpportunitiesPage";
import LearningPage from "@pages/onboarding/LearningPage";
import LearnPage from "@pages/onboarding/LearnPage";
import ProductivityPage from "@pages/onboarding/ProductivityPage";
import ProgressPage from "@pages/onboarding/ProgressPage";
import TailoredPage from "@pages/onboarding/TailoredPage";
import TopicsPage from "@pages/onboarding/TopicsPage";
import v1OnboardingRoutes from "@pages/onboarding/v1/v1OnboardingRoutes";
import v2OnboardingRoutes from "@pages/onboarding/v2/v2OnboardingRoutes";
import YouTubeCoveredPage from "@pages/onboarding/YouTubeCoveredPage";
import YouTubeOrPodcastsPage from "@pages/onboarding/YouTubeOrPodcastsPage";
import { Navigate, Outlet } from "react-router-dom";

const onboardingRoutes = [
  {
    path: "/onboarding",
    element: <Outlet />,
    loader: skipOnboardingLoader,
    children: [
      {
        element: <Navigate to="age" replace />,
        index: true,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AgePage />,
        loader: getOnboardingFlagLoader,
        path: "age",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ComplexConceptsPage />,
        path: "complex",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GenderPage />,
        path: "gender",
      },
      {
        children: growthOnboardingRoutes,
        element: <Outlet />,
        path: "growth",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnPage />,
        path: "learn",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearningPage />,
        path: "learning",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnFasterPage />,
        path: "learn-faster",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FullPotentialPage />,
        path: "potential",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProductivityPage />,
        path: "productivity",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProgressPage />,
        path: "progress",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearningOpportunitiesPage />,
        path: "opportunities",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TailoredPage />,
        path: "tailored",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TopicsPage />,
        path: "topics",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <YouTubeCoveredPage />,
        path: "youtube-covered",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <YouTubeOrPodcastsPage />,
        path: "youtube-or-podcasts",
      },
      {
        children: v1OnboardingRoutes,
        element: <Outlet />,
        path: "v1",
      },
      {
        children: v2OnboardingRoutes,
        element: <Outlet />,
        path: "v2",
      },
    ],
  },
];

export default onboardingRoutes;
