import { ReactNode } from "react";

export default function LayoutScrollable({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <main className="custom-container flex h-full min-h-dvh flex-col">
      {children}
    </main>
  );
}
